<template>
    <section id="dashboard-new-form">
        <b-card no-body>
            <b-card-body class="p-5 d-flex flex-column">
                <loading v-if="loading" />
                <h3 class="text-left text-secondary font-weight-bolder">
                    {{ title }}
                </h3>

                <div class="flex-grow-1 mt-4">
                    <b-form-input
                        v-model="form.name"
                        class="mt-3"
                        placeholder="Nome do Formulário *"
                        required
                        :readonly="readonly"
                        type="text"
                    />

                    <div
                        v-for="(field, index) in form.fields"
                        :key="`form--field--item--${index}`"
                        :class="`mt-3 ${
                            field.constructor.name === 'Object' ? 'd-flex' : ''
                        }`"
                    >
                        <div
                            v-if="field.constructor.name === 'Object'"
                            class="d-flex flex-grow-1"
                        >
                            <div
                                class="d-flex flex-column align-items-end flex-grow-1"
                            >
                                <b-form-input
                                    :placeholder="`${field.name} ${
                                        field.required ? '*' : ''
                                    }`"
                                    :required="field.required"
                                    readonly
                                    type="text"
                                />
                                <div>
                                    <b-form-checkbox
                                        v-if="
                                            !['name', 'main_phone'].includes(
                                                field.type
                                            )
                                        "
                                        v-model="field.required"
                                        :disabled="readonly"
                                    >
                                        Campo obrigatório
                                    </b-form-checkbox>
                                </div>
                            </div>
                            <button
                                v-if="
                                    !['name', 'main_phone'].includes(
                                        field.type
                                    ) && !readonly || readonly && !integrationFields.includes(field.name)
                                "
                                class="my-1 ml-3 border border-secondary rounded-circle p-1 remove-btn d-flex justify-content-center align-items-center"
                                @click="handleRemoveField(field)"
                            >
                                <font-awesome-icon icon="fa-solid fa-minus" />
                            </button>
                        </div>

                        <div
                            v-if="field.constructor.name === 'Array'"
                            class="row"
                        >
                            <div
                                v-for="(col_field, col_index) in field"
                                :key="`form--row--${index}--col--${col_index}`"
                                class="col d-flex"
                            >
                                <div
                                    class="d-flex flex-column align-items-end flex-grow-1"
                                >
                                    <b-form-input
                                        :placeholder="`${col_field.name} ${
                                            col_field.required ? '*' : ''
                                        }`"
                                        readonly
                                        required
                                        type="text"
                                    />
                                    <div>
                                        <b-form-checkbox
                                            v-if="
                                                ![
                                                    'name',
                                                    'main_phone',
                                                ].includes(col_field.type)
                                            "
                                            v-model="col_field.required"
                                        >
                                            Campo obrigatório
                                        </b-form-checkbox>
                                    </div>
                                </div>
                                <button
                                    v-if="
                                        !['name', 'main_phone'].includes(
                                            col_field.type
                                        )
                                    "
                                    class="my-1 ml-3 border border-secondary rounded-circle p-1 remove-btn d-flex justify-content-center align-items-center"
                                    @click="handleRemoveField(col_field, index)"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-minus"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        class="d-flex justify-content-start my-5"
                    >
                        <h5
                            class="text-left text-secondary font-weight-bolder"
                            role="button"
                            @click="handleAddField(form.fields)"
                        >
                            <font-awesome-icon
                                class="mr-4"
                                icon="fa-solid fa-plus"
                            />
                            Adicionar um novo campo
                        </h5>
                    </div>
                </div>

                <div class="row mt-4">
                    <div
                        class="col-12 col-md-8 offset-md-4 col-lg-6 offset-lg-6 col-xl-4 offset-xl-8 row pr-0 pr-md-3"
                    >
                        <div class="col pr-0 my-1">
                            <button
                                class="btn btn-outline-primary w-100"
                                @click.prevent="goToHome"
                            >
                                Cancelar
                            </button>
                        </div>
                        <div
                            class="col-12 col-sm-6 col-md-6 pr-0 my-1"
                        >
                            <button
                                class="btn w-100 btn-primary"
                                @click.prevent="askDeleted"
                            >
                                Salvar Padrão
                            </button>
                        </div>
                    </div>
                </div>
            </b-card-body>
        </b-card>
    </section>
</template>

<script>
import { BCard, BCardBody, BFormInput, BFormCheckbox } from "bootstrap-vue";
import api from "@/api";
import { acceptedFieldNameRegex, defaultForm, integrationFields } from "@/api/constants";
import Loading from "@/views/components/Loading.vue";

export default {
    components: {
        BCard,
        BCardBody,
        BFormInput,
        BFormCheckbox,
        Loading,
    },
    data() {
        return {
            form: {
                _id: null,
                name: "",
                fields: [...defaultForm],
            },
            loading: false,
            fieldRemoved: false,
            integrationFields
        };
    },
    beforeDestroy() {
        this.form.fields = defaultForm;
    },
    created() {
        if (this.$route.name == "forms/edit") {
            this.getForm(this.$route.params.id);
        }
    },
    computed: {
        canReturnToPreview() {
            return localStorage.getItem("@taglab/previous-page") != null;
        },
        previousPage() {
            if (this.canReturnToPreview) {
                return atob(localStorage.getItem("@taglab/previous-page"));
            }

            return null;
        },
        title() {
            const form = this.form;
            if (form._id) {
                return "Editar Padrão de Formulário";
            } else {
                return "Novo Padrão de Formulário";
            }
        },
        readonly() {
            return Boolean(this.form._id && this.form.integration_id);
        },
    },
    methods: {
        goToHome() {
            this.$swal({
                title: "Operação cancelada",
                icon: "info",
                showConfirmButton: false,
                timer: 1500,
            }).then(() => {
                this.$router.replace({ name: "register" });
            });
        },
        alert(title, text, errors = [], icon = "error") {
            this.$swal({
                title,
                html: errors.length > 0 ? errors.join("<br/>") : text,
                icon,
                confirmButtonText: "Continuar",
                confirmButtonColor: "#317f98",
            }).then((result) => {
                if (result["isConfirmed"] && icon === "success") {
                    if (this.canReturnToPreview) {
                        this.$router.push({ name: this.previousPage });
                        localStorage.removeItem("@taglab/previous-page");
                    }
                }
            });
        },
        handleValidate() {
            if (this.form.name == "" || this.form.name == null) {
                return [
                    "Preencha o nome do formulário",
                    "Todo formulário deve ter um nome de identificação.",
                ];
            }
            if (
                !this.form.fields
                    .flat()
                    .some((field) => field.type == "main_phone")
            ) {
                return [
                    "Telefone principal obrigatório",
                    "Todo formulário deve ter um telefone principal (Tipo: telefone).",
                ];
            }
            if (
                !this.form.fields
                    .flat()
                    .some(
                        (field) => field.type == "main_phone" && field.required
                    )
            ) {
                return [
                    "Telefone principal obrigatório",
                    "O telefone principal deve ser um campo obrigatório (Tipo: telefone).",
                ];
            }
            if (
                !this.form.fields.flat().some((field) => field.type === "name")
            ) {
                return [
                    "Nome principal obrigatório",
                    "Todo formulário deve ter um nome principal referente ao cliente. (Tipo: nome)",
                ];
            }
            if (
                !this.form.fields
                    .flat()
                    .some((field) => field.type === "name" && field.required)
            ) {
                return [
                    "Nome principal obrigatório",
                    "O nome principal deve ser um campo obrigatório (Tipo: nome).",
                ];
            }
            return false;
        },
        askDeleted() {
            if (this.fieldRemoved && this.form._id) {
                this.$swal({
                    title: "Você removeu um campo",
                    text: "Você removeu um campo do formulário, isso vai afetar os dados já cadastrados. Deseja continuar?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Sim, continuar",
                    cancelButtonText: "Não, cancelar",
                    confirmButtonColor: "#317f98",
                    cancelButtonColor: "#d33",
                }).then((result) => {
                    if (result["isConfirmed"]) {
                        this.handleSubmit();
                    }
                });
            } else {
                this.handleSubmit();
            }
        },
        handleSubmit() {
            const result = this.handleValidate();
            if (result) {
                const [title, text] = result;
                return this.$swal(title, text, "error");
            }

            this.loading = true;
            const id = this.form._id;
            api({
                url: !id ? "/forms" : `/forms/${id}`,
                method: !id ? "POST" : "PUT",
                data: {
                    name: this.form.name,
                    fields: this.form.fields,
                },
            })
                .then((response) => {
                    const { message, errors, type } = response.data;

                    if (!id) {
                        this.$store.commit("form/SET_ONE", [
                            response.data.body,
                        ]);
                    }

                    if (type === "success") {
                        return this.alert(
                            "Padrão salvo com sucesso!",
                            "Todos os dados já estão em seu banco.",
                            [],
                            "success"
                        );
                    }
                    return this.alert(
                        "Problema ao salvar padrão",
                        message,
                        errors
                    );
                })
                .catch((error) => {
                    console.log(error.response);
                    if (error.response) {
                        const { message, errors } = error.response.data;
                        return this.alert(
                            "Problema encontrado!",
                            message,
                            errors
                        );
                    }
                    this.alert(
                        "Problema encontrado!",
                        "Ocorreu um erro inesperado. Aguarde alguns minutos e tente novamente."
                    );
                })
                .finally(() => (this.loading = false));
        },
        handleAddField(fields) {
            this.$swal({
                title: "Novo campo",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: "Adicionar",
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                html: `
                    <input type="text" id="swal-input-text" placeholder="Nome do campo" class="swal2-input">
                    <select id="swal-input-select" class="swal2-input">
                        <option value="text" selected>Texto</option>
                        <option value="phone">Telefone</option>
                        <option value="number">Número</option>
                        <option value="email">Email</option>
                        <option value="date">Data</option>
                        <option value="money">Moeda</option>
                    </select>
                    <input type="checkbox" id="swal-input-check">
                    <label for="swal-input-check">Adicionar na última linha</label>
                `,
                preConfirm: () => {
                    return [
                        document.getElementById("swal-input-text").value,
                        document.getElementById("swal-input-check").checked,
                        document.getElementById("swal-input-select").value,
                    ];
                },
            }).then((result) => {
                if (!result.isConfirmed) return;

                const {
                    value: [name, isCol, type],
                } = result;
                if (name.length < 2)
                    return this.$swal(
                        "Nome inválido",
                        "O nome do campo deve ter no mínimo 5 caracteres",
                        "error"
                    );

                if (!acceptedFieldNameRegex.test(name))
                    return this.$swal(
                        "Caracteres inválidos",
                        "Apenas letras, números, hífens e underlines são permitidos.",
                        "error"
                    );

                if (type == "")
                    return this.$swal(
                        "Tipo vazio",
                        "Selecione um tipo para continuar",
                        "error"
                    );

                if (
                    type == "phone" &&
                    this.form.fields
                        .flat()
                        .some((field) => field.type == "phone")
                )
                    return this.$swal(
                        "Apenas um número principal",
                        "Só pode selecionar um número principal por formulário.",
                        "error"
                    );

                if (
                    type == "name" &&
                    this.form.fields
                        .flat()
                        .some((field) => field.type == "name")
                )
                    return this.$swal(
                        "Apenas um nome principal",
                        "Só pode selecionar um nome principal por formulário.",
                        "error"
                    );

                const newField = {
                    name,
                    type,
                    value: "",
                    required: ["phone", "name"].includes(type),
                };

                if (!isCol) {
                    return fields.push(newField);
                }

                if (fields.length == 0)
                    return this.$swal(
                        "Nenhuma linha para adicionar",
                        "Não tem como adicionar uma coluna na última linha se ela não existe",
                        "error"
                    );

                const last = fields[fields.length - 1];
                if (last.constructor.name === "Array" && !(last.length < 3))
                    return this.$swal(
                        "Linha completa",
                        "O formulário pode ter no máximo 3 campos por linha",
                        "error"
                    );

                if (last.constructor.name === "Array") {
                    return fields[fields.length - 1].push(newField);
                }
                fields.pop();
                return fields.push([last, newField]);
            });
        },
        handleRemoveField(field, rowIndex = -1) {
            if (rowIndex == -1) {
                const index = this.form.fields.findIndex((e) => e === field);
                this.fieldRemoved = true;
                return this.form.fields.splice(index, 1);
            }

            if (this.form.fields[rowIndex].length == 1) {
                this.fieldRemoved = true;
                return this.form.fields.splice(rowIndex, 1);
            }

            const index = this.form.fields[rowIndex].findIndex(
                (e) => e === field
            );
            this.form.fields[rowIndex].splice(index, 1);
            this.fieldRemoved = true;
        },
        getForm(id) {
            this.loading = true;
            api.get(`/forms/${id}`)
                .then((response) => {
                    const { type, body, message } = response.data;
                    if (type == "success") {
                        if (body.integration_id) {
                            body.fields.map((field) => field.required = integrationFields.includes(field.name))
                        }
                        return Object.assign(this.form, body);
                    }
                    throw message;
                })
                .catch((error) => {
                    var message = "Não foi possível carregar formulário";
                    if (error.response) {
                        message = error.response.data.message;
                    }
                    this.$bvToast.toast(message, {
                        title: "Formulários",
                        variant: "danger",
                    });
                    this.$router.replace({ name: "forms/new" });
                })
                .finally(() => (this.loading = false));
        },
    },
};
</script>
