var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"dashboard-new-form"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"p-5 d-flex flex-column"},[(_vm.loading)?_c('loading'):_vm._e(),_c('h3',{staticClass:"text-left text-secondary font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"flex-grow-1 mt-4"},[_c('b-form-input',{staticClass:"mt-3",attrs:{"placeholder":"Nome do Formulário *","required":"","readonly":_vm.readonly,"type":"text"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_vm._l((_vm.form.fields),function(field,index){return _c('div',{key:`form--field--item--${index}`,class:`mt-3 ${
                        field.constructor.name === 'Object' ? 'd-flex' : ''
                    }`},[(field.constructor.name === 'Object')?_c('div',{staticClass:"d-flex flex-grow-1"},[_c('div',{staticClass:"d-flex flex-column align-items-end flex-grow-1"},[_c('b-form-input',{attrs:{"placeholder":`${field.name} ${
                                    field.required ? '*' : ''
                                }`,"required":field.required,"readonly":"","type":"text"}}),_c('div',[(
                                        !['name', 'main_phone'].includes(
                                            field.type
                                        )
                                    )?_c('b-form-checkbox',{attrs:{"disabled":_vm.readonly},model:{value:(field.required),callback:function ($$v) {_vm.$set(field, "required", $$v)},expression:"field.required"}},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)],1),(
                                !['name', 'main_phone'].includes(
                                    field.type
                                ) && !_vm.readonly || _vm.readonly && !_vm.integrationFields.includes(field.name)
                            )?_c('button',{staticClass:"my-1 ml-3 border border-secondary rounded-circle p-1 remove-btn d-flex justify-content-center align-items-center",on:{"click":function($event){return _vm.handleRemoveField(field)}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-minus"}})],1):_vm._e()]):_vm._e(),(field.constructor.name === 'Array')?_c('div',{staticClass:"row"},_vm._l((field),function(col_field,col_index){return _c('div',{key:`form--row--${index}--col--${col_index}`,staticClass:"col d-flex"},[_c('div',{staticClass:"d-flex flex-column align-items-end flex-grow-1"},[_c('b-form-input',{attrs:{"placeholder":`${col_field.name} ${
                                        col_field.required ? '*' : ''
                                    }`,"readonly":"","required":"","type":"text"}}),_c('div',[(
                                            ![
                                                'name',
                                                'main_phone',
                                            ].includes(col_field.type)
                                        )?_c('b-form-checkbox',{model:{value:(col_field.required),callback:function ($$v) {_vm.$set(col_field, "required", $$v)},expression:"col_field.required"}},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)],1),(
                                    !['name', 'main_phone'].includes(
                                        col_field.type
                                    )
                                )?_c('button',{staticClass:"my-1 ml-3 border border-secondary rounded-circle p-1 remove-btn d-flex justify-content-center align-items-center",on:{"click":function($event){return _vm.handleRemoveField(col_field, index)}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-minus"}})],1):_vm._e()])}),0):_vm._e()])}),_c('div',{staticClass:"d-flex justify-content-start my-5"},[_c('h5',{staticClass:"text-left text-secondary font-weight-bolder",attrs:{"role":"button"},on:{"click":function($event){return _vm.handleAddField(_vm.form.fields)}}},[_c('font-awesome-icon',{staticClass:"mr-4",attrs:{"icon":"fa-solid fa-plus"}}),_vm._v(" Adicionar um novo campo ")],1)])],2),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 col-md-8 offset-md-4 col-lg-6 offset-lg-6 col-xl-4 offset-xl-8 row pr-0 pr-md-3"},[_c('div',{staticClass:"col pr-0 my-1"},[_c('button',{staticClass:"btn btn-outline-primary w-100",on:{"click":function($event){$event.preventDefault();return _vm.goToHome.apply(null, arguments)}}},[_vm._v(" Cancelar ")])]),_c('div',{staticClass:"col-12 col-sm-6 col-md-6 pr-0 my-1"},[_c('button',{staticClass:"btn w-100 btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.askDeleted.apply(null, arguments)}}},[_vm._v(" Salvar Padrão ")])])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }